import React from "react"

import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"

import Layout from "../../components/layout"
import Head from "../../components/head"

const useStyles = makeStyles(theme => ({
  heroContent: {
    padding: theme.spacing(15, 0, 12),

    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(9, 0, 4),
    },
  },
}))

const CommercialMatters = () => {
  const classes = useStyles()

  return (
    <Layout>
      <Head title="Commercial Matters" />
      <main>
        <div className={classes.heroContent}>
          <Container maxWidth="md">
            <Typography
              component="h1"
              variant="h3"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              Commercial Matters - leases, buying and selling of businesses
            </Typography>
            <Typography
              component="h2"
              variant="h4"
              color="textPrimary"
              gutterBottom
            >
              Leases
            </Typography>
            <Typography component="p" paragraph>
              The first step in a lease is to check whether it is covered by the
              Retail Leases Act. If so, there are a number of formal documents
              to be signed as well as information brochures provided to the
              tenant. In the Retail Leases Act, the legal costs for the
              preparation of a lease are met by the landlord.
            </Typography>
            <Typography component="p" paragraph>
              The issues that are relevant to all leases include:
            </Typography>

            <Typography component="ul" paragraph>
              <Typography component="li">
                <b>Shop description:</b> Folio identifier, address, size,
                whether there is a car parking space, the condition of lessor
                fixtures and fittings e.g. lighting, separate electricity box,
                floor coverings, toilet facilities for staff and clients.
              </Typography>
              <Typography component="li">
                <b>Length of lease:</b> The commencement and end date of the
                lease including option periods
              </Typography>
              <Typography component="li">
                <b>Rent:</b> How much is the rent, whether rent includes all
                outgoings, whether there is a rent free period, what is the
                annual rent review procedure
              </Typography>
              <Typography component="li">
                <b>Suitability of the shop:</b> Whether your business in the
                shop meets the permissible use by the Council, whether there are
                any outstanding health and building notices by Council, whether
                there are any restrictions on external advertising space on the
                building
              </Typography>
              <Typography component="li">
                <b>Outgoings</b>: The full costs of outgoings that the tenant
                needs to pay.
              </Typography>
              <Typography component="li">
                <b>Bond</b> or <b>security deposit</b> required and whether this
                is invested with the Retail Tenancy Unit
              </Typography>
              <Typography component="li">
                Who repairs and who maintains the property and equipment
              </Typography>
              <Typography component="li">
                <b>Access to property</b> – whether the tenant has 24 hours
                access. Core trading hours, when shops must be open for
                business.
              </Typography>
            </Typography>

            <Typography
              component="h2"
              variant="h4"
              color="textPrimary"
              gutterBottom
            >
              Shopping Centre Leases
            </Typography>

            <Typography component="p" paragraph>
              There would be additional Shopping centre requirements. The
              Landlord needs to provide:
            </Typography>

            <Typography component="ul" paragraph>
              <Typography component="li">Annual sales of the centre</Typography>
              <Typography component="li">
                Turnover for specialty shops per square metre using at least
                three categories (food, non-food and services)
              </Typography>
              <Typography component="li">Centre traffic count</Typography>
              <Typography component="li">
                Details of fitout construction standards
              </Typography>
              <Typography component="li">
                Details of when the leases for major tenants end.
              </Typography>
            </Typography>

            <Typography
              component="h2"
              variant="h4"
              color="textPrimary"
              gutterBottom
            >
              Buying and Selling a Business
            </Typography>
            <Typography component="p" paragraph>
              To purchase a business you need to check:
            </Typography>
            <Typography component="ul" paragraph>
              <Typography component="li">
                with your accountant as to the best business structure for the
                running of the business e.g. as sole trader, partnership,
                company, or trust structure etc.
              </Typography>
              <Typography component="li">
                a detailed sale of business contract which includes business
                name/s, internet services, web-site, inventory, depreciation
                schedule, lease, and franchise agreements (if applicable);
              </Typography>
              <Typography component="li">
                the current lease requirements to ensure that they are
                financially manageable;
              </Typography>
              <Typography component="li">
                whether you can obtain the landlord’s consent for the transfer
                of the lease or for a new lease to you
              </Typography>
              <Typography component="li">
                you can obtain all necessary licenses to run the business;
              </Typography>
              <Typography component="li">
                with the Local Council for permissible use of the premises for
                your business and also whether you need to provide a development
                application for your business renovations
              </Typography>
              <Typography component="li">
                whether the Vendor will provide a training period for you to run
                the business prior to settlement and after settlement of the
                purchase
              </Typography>
              <Typography component="li">
                whether you wish to employ any of the current staffing and if so
                whether you would be liable for any leave entitlements or long
                service leave for staff.
              </Typography>
            </Typography>

            <Typography component="p" paragraph>
              In particular, check the financial records and profitability of
              the business, and where possible compare with other similar type
              businesses for sale. Check the business financial records with
              your accountant to consider whether the business is viable and
              also being sold at a fair price.
            </Typography>
            <Typography component="p" paragraph>
              You should work in the business for a few weeks to check business
              turnover and operating issues.
            </Typography>
          </Container>
        </div>
      </main>
    </Layout>
  )
}

export default CommercialMatters
